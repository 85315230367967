import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { PhoneIcon } from '@heroicons/react/20/solid'
import { Cog6ToothIcon } from '@heroicons/react/24/outline'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Menu from '../components/menu'
import Seo from '../components/seo'
import Wordmark from '../../assets/wordmark.svg'

const SolutionsPage = ({ active = `solutions` }) => (
  <Layout>
    <Seo title="My Work and Well-Being | Leadership Development" />
    <div className="max-w-[1200px] mx-auto">
      <div className="mx-auto px-6 py-6 lg:flex lg:items-baseline lg:justify-between lg:px-8 text-center h-auto">
        <Wordmark className="max-w-sm inline pb-14 lg:pb-0" />
        <Menu active={active} />
      </div>
    </div>
    <div className="h-60 bg-black"></div>
    <div className="max-w-[1200px] mx-auto -mt-44 text-center">
      <p className="text-myorange text-3xl lg:text-4xl pb-4 pl-6 font-tbold text-left">
        Leadership Development
      </p>
      <StaticImage
        src="../images/l-1.jpg"
        alt="Decorative image of a healthy workplace team"
        className="rounded-2xl m-6 lg:p-0"
      />
    </div>
    <section className="py-8 max-w-[1200px] mx-auto bg-white">
      <div className="md:max-w-4xl px-8 md:mx-auto">
        <h3 className="text-mypurple text-2xl md:text-3xl mb-8">Solutions</h3>
        <p className="text-lg md:text-xl mb-6 text-gray-800 text-left">
          We work with organizational leaders to foster, promote, and maintain a
          Psychologically Healthy Workplace. Some additional areas include
          Employee Financial Well-being, Employee Engagement, Employee
          Communication, Well-being at Work, Stress in the workplace,
          Occupational Health, Culture, Leadership, People Management, Values
          and Principles, and more.
        </p>
      </div>
      <section className="py-12 max-w-[1200px] mx-auto bg-white">
        <div className="md:max-w-4xl px-8 md:mx-auto">
          <div>
            <p className="text-left text-mypurple text-lg font-bold">
              An overview:
            </p>
            <ul className="text-xl md:text-2xl lg:basis-1/2 text-left text-gray-600 tracking-tight font-tbold checks">
              <li className="mt-4">Leadership Training and Development</li>
              <li className="mt-4">
                Leadership Workplace Health and Wellbeing Training and
                Development
              </li>
              <li className="mt-4">
                Mental Health in the Workplace Training and Development
              </li>
              <li className="mt-4">Employee Engagement and Motivation</li>
              <li className="mt-4">
                Customized Leadership Services and Workshops to meet
                organizational needs
              </li>
            </ul>
          </div>
        </div>
      </section>
    </section>
    <section className="pt-24 pb-12 my-12 max-w-[1200px] mx-auto bg-slate-50">
      <div className="md:max-w-4xl px-8 md:mx-auto">
        <p className="text-2xl md:text-3xl mb-6 text-myblue text-center">
          &quot;The single biggest way to impact an organization is to focus on
          leadership development. There is almost no limit to the potential of
          an organization that recruits good people, raises them up as leaders
          and continually develops them&quot;
        </p>
        <p className="text-2xl md:text-3xl mb-6 text-myblue text-center pt-2">
          - John Maxwell
        </p>
        <ul className="text-lg md:text-xl mb-6 text-gray-800 text-left pt-8">
          <li className="mt-2">
            It is essential for employers to measure their executive and middle
            managers’ level of influence through the leadership assessment, as
            well as feedback from others around the organization (i.e.,
            employees, customers, stakeholders, etc.) This information can then
            be used to target your leadership growth.
          </li>
          <li className="mt-6">
            This leadership assessment tool is designed to garner feedback from
            managers and others around them to identify areas and opportunities
            for a manager’s leadership growth and development.
          </li>
          <li className="mt-6">
            Research confirms that promoting a healthy and productive workforce
            requires leaders who aspire to create a healthy organization will
            take a holistic and long-term view of investing in people.
          </li>
          <li className="mt-6">
            Effective leaders also foster a culture that promotes employee
            well-being not just as an end itself but as a key determinant of
            customer satisfaction, product or service quality, and shareholder
            value. These are the biggest well-being dividends of all.
          </li>
        </ul>
      </div>
    </section>

    <section className="flex flex-nowrap items-center justify-evenly p-6 my-12 bg-mypurple max-w-[1200px] mx-auto">
      <div className="flex items-center mr-6 text-white">
        <Cog6ToothIcon className="h-12 w-12" />{' '}
        <span className="text-2xl lg:text-3xl pl-2">
          Ready to Shift Gears and Take Action?
        </span>
      </div>
      <div className="flex items-center flex-shrink-0 mr-6 text-white font-tbold">
        <Link
          type="button"
          to="/bookcall"
          className="inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm text-mypurple shadow-sm hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PhoneIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Book a Call
        </Link>
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12">
        <iframe
          width="100%"
          height="600"
          src="https://www.youtube.com/embed/g320ycpvCto"
          title="Video"
        ></iframe>
      </div>
    </section>
    <section>
      <div className="max-w-[1200px] mx-auto my-12 text-center">
        <StaticImage
          src="../images/l-2.jpg"
          alt="Decorative image depicting employee working together"
          className="h-full"
        />
      </div>
    </section>
  </Layout>
)

export default SolutionsPage
